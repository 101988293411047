import * as React from 'react'
import fireInspection from '../images/fire-inspection.png';
import certification from '../images/certification.png';
import seminar from '../images/workplace-training.jpg';
import projectManagement from '../images/project-management.jpg';

interface Props {
}

const Services : React.FC<Props> = () => {
    return (
        <section className="class section" id="services">
               <div className="container">
                    <div className="row">
                            <div className="col-lg-12 col-12 text-center mb-5">
                                <h2 data-aos="fade-up" data-aos-delay="200">Our Services</h2>
                             </div>

                            <div className="col-lg-6 col-md-6 col-12 pb-4" data-aos="fade-up" data-aos-delay="400">
                                <div className="class-thumb">
                                    <img src= {fireInspection} className="img-fluid" alt="Class"/>

                                    <div className="class-info">
                                        <h3 className="mb-1">Inspections</h3>

                                        <p className="mt-3">Scorch Fire Solutions conducts annual passive fire inspections as per the requirements of AS1851-2012. These inspections include service penetration fire & smoke seals, doors, dampers and fire & smoke barrier construction. Other types of inspections include occupancy & egress travel path reviews for the safe occupancy & evacuation of buildings. The establishment of Baseline Data for future maintenance inspections can also be provided as part of the inspection process.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 mt-lg-0 mt-md-0 col-lg-6 col-md-6 col-12 pb-4" data-aos="fade-up" data-aos-delay="800">
                                <div className="class-thumb">
                                    <img src={certification} className="img-fluid" alt="Class"/>

                                    <div className="class-info">
                                        <h3 className="mb-1">Certification</h3>

                                        <p className="mt-3">Independent certification is a vital part of all passive fire systems installations and Scorch Fire Solutions has the experience and knowledge to inspect and review new passive fire systems to provide certification as part of the construction certification process for the principal certifying authority.</p>

                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 mt-lg-0 col-lg-6 col-md-6 col-12 pb-4" data-aos="fade-up" data-aos-delay="400">
                                <div className="class-thumb">
                                    <img src={seminar} className="img-fluid" alt="Class"/>

                                    <div className="class-info">
                                        <h3 className="mb-1">Workplace Training</h3>


                                        <p className="mt-3">Scorch Fire Solutions offers in-house, on-site or online training seminars tailored to suit individual clients requirements from a general passive fire presentation to a project specific presentation detailing the requirements of AS4072.1 for the installation of passive fire systems.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 mt-lg-0 col-lg-6 col-md-6 col-12 pb-4" data-aos="fade-up" data-aos-delay="800">
                                <div className="class-thumb">
                                    <img  src={projectManagement} className="img-fluid" alt="Class"/>

                                    <div className="class-info">

                                        <h3 className="mb-1">Project Management</h3>

                                        <p className="mt-3">Scorch Fire Solutions evolved from over 20 years of contracting experience and can apply this to project manage fire safety upgrades with a hands on site based approach to ensure passive fire upgrades & installations are installed as per the Tested Systems and ongoing advice is readily available to ensure the client is delivered compliant solutions at all times. </p>
           
                                    </div>
                                </div>
                            </div>

                    </div>
               </div>
     </section>
    );
}

export default Services