import * as React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

interface Props {
}

const Hero : React.FC<Props> = () => {
    return (            
    <section className="hero d-flex flex-column justify-content-center align-items-center" id="home">

                    <div className="bg-overlay"></div>

                    <div className="container">
                            <div className="row">

                                <div className="col-lg-8 col-md-10 mx-auto col-12">
                                    <div className="hero-text mt-5 text-center">

                                            <h1 data-aos="fade-up" data-aos-delay="500">Protecting what matters to you</h1>

                                            <h6 className = "text-white" data-aos="fade-up" data-aos-delay="300">CALL 0404 089 716 </h6>
.
                                            <button onClick={() => scrollTo('#feature')} className="btn custom-btn smoothScroll mt-3">More Info</button>
                                            
                                            <button onClick={() => scrollTo('#contact')} className="btn custom-btn smoothScroll bordered mt-3">Contact Us</button>
                          
                                    </div>
                                </div>

                            </div>
                    </div>
            </section>);
}

export default Hero