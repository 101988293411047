import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

interface Props {
}

const Footer : React.FC<Props> = () => {
    return (     
    <footer className="site-footer">
          <div className="container">
               <div className="row">

                    <div className="ml-auto col-lg-4 col-md-5">
                        <h6>Address</h6>
                        <p className="copyright-text">PO Box 3183, Weston Creek, ACT 2611</p>
                    </div>

                    <div className="justify-content-center mx-auto col-lg-5 col-md-7 col-12">
                        <h6>Contact</h6>
                        <p className="mr-4">
                            <FontAwesomeIcon icon={faEnvelope} /> murray@scorchfire.com.au</p>
                        <p><FontAwesomeIcon icon={faPhone} /> 0404 089 716</p>
                    </div>
               </div>
          </div>
     </footer>);
}

export default Footer;