import * as React from 'react'

interface Props {
}

const Feature : React.FC<Props> = () => {
    return (
    <section className="feature section" id="feature">
    <div className="container">
            <div className = "row"><h2 className="mb-3 col text-center" data-aos="fade-up">Canberras Prestige Fire Consultancy</h2></div>
            <div className="row"><p className="col text-center text-white" data-aos="fade-up">Scorch Fire Solutions Pty Ltd is a Canberra based fire protection consultancy that provides fire protection solutions and project management services for new and existing buildings in the ACT and NSW.</p></div>
        </div>
     </section>
     );
}

export default Feature