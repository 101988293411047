import * as React from "react"
import AboutUs from "../components_static/AboutUs"
import ContactUs from "../components_static/ContactUs"
import Feature from "../components_static/Feature"
import Footer from "../components_static/Footer"
import Hero from "../components_static/Hero"
import Navbar from "../components_static/Navbar"
import SEO from "../components_static/seo"
import Services from "../components_static/Services"
import 'bootstrap/dist/css/bootstrap.min.css'; //import bootstrap
import "../styles/index.css";


interface Props {}

const Index: React.FC<Props> = () => {
  return (
    <>
      <SEO title="Home" />
      {/* Site Navbar Here */}
      <Navbar signedIn = {false}/>
      {/* Render Hero Image */}
      <Hero/>
      {/* Render Feature Component */}
      <Feature/>
      {/* About Us Page */}
      <AboutUs/>
      {/* Services */}
      <Services/>
      {/* Contact Us */}
      <ContactUs/>
      {/* Footer */}
      <Footer/>

    </>
  )
}

export default Index
