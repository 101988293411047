import * as React from 'react'

interface Props {
}

const AboutUs : React.FC<Props> = () => {
    return (
    <section className="about section" id="about">
    <div className="container">
         <div className="row">

                 <div className="mt-lg-5 mb-lg-0 mb-4 col-lg-5 col-md-10 mx-auto col-12">
                     <h2 className="mb-4" data-aos="fade-up" data-aos-delay="300">About Us</h2>

                     <p data-aos="fade-up" data-aos-delay="400">Backed by years of experience in the fire protection industry, Scorch Fire Solutions has the experience and knowledge to deliver professional, effective services to our clients. We founded our company on the importance of fire & life safety, and we work hard to see that through. For commercial and government clients, we alleviate the confusion around proper fire safety measures and provide passive fire systems and maintenance routines to protect what matters most to you.</p>

                 </div>

                 <div className="mt-lg-5 mb-lg-0 mb-4 col-lg-5 col-md-10 mx-auto col-12">
                     <h2 className="mb-4" data-aos="fade-up" data-aos-delay="300">Efficient, Professional, Prompt</h2>

                     <p data-aos="fade-up" data-aos-delay="400">Scorch Fire Solutions brings fire safety to you! We can provide on-site installation training & evaluation, so fire safety doesn’t have to interrupt the flow of your business. Working together is a collaborative process. We take our time to ensure you understand exactly how and what fire safety measure we’re taking to protect your property and how you should react in the event of an emergency. </p>

                 </div>
         </div>
    </div>
</section>);
}

export default AboutUs