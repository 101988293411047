import Form, { useForm } from "antd/lib/form/Form"
import FormItem from "antd/lib/form/FormItem"
import Input from "antd/lib/input/Input"
import "antd/lib/input/style/index.css"
import "antd/lib/form/style/index.css"
import * as React from "react"
import TextArea from "antd/lib/input/TextArea"

interface Props {}

const ContactUs: React.FC<Props> = () => {
  const [emailNotification, setEmailNotification] = React.useState("");
  const [form] = useForm();

  //This method takes senderName, senderEmail and message and sends them to the emailer API
  const sendEmail = async () => {
    console.log("Hello");
    //First, validate the form
    const isValid = await form.validateFields();

    console.log(isValid);

    if (isValid) {
      const response = await fetch(
        "https://iyku9aevr1.execute-api.ap-southeast-2.amazonaws.com/email",
        {
          method: "POST",
          body: JSON.stringify({
            name: await form.getFieldValue("name"),
            email: await form.getFieldValue("email"),
            message: await form.getFieldValue("message"),
          }),
        }
      )

      //If the response is okay
      if (response.ok) {
        // reset the form 
        form.resetFields();
        //Create a success message notification
        setEmailNotification(
          "A message has been successfully sent to ScorchFire, we will get back to you as soon as possible."
        )
      } else {
        setEmailNotification(
          "An error occured using the emailer, please send an email to murray@scorchfire.com.au using your own email client or call us at 0404 089 716"
        )
      }
    }
  }

  return (
    <section className="contact section" id="contact">
      <div className="container">
        {emailNotification != "" ? (
          <div className="alert alert-primary">{emailNotification}</div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="ml-auto col-12">
            <h2 className="mb-4 pb-2" data-aos="fade-up" data-aos-delay="200">
              Contact Us
            </h2>

            <div
              className="contact-form webform"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <Form form={form}>
                <FormItem
                  name="name"
                  label="Name:"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: "You must enter a name.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your name here"
                    style={{ fontSize: "18px" }}
                  />
                </FormItem>

                <FormItem
                  name="email"
                  label="Email Address:"
                  required={true}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "You must enter a valid contact email.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your contact email here"
                    style={{ fontSize: "18px" }}
                  />
                </FormItem>

                <FormItem
                  name="message"
                  label="Message:"
                  required={true}
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (value.length < 1 || value.length > 500) {
                          return Promise.reject(
                            "The message must be between 1 and 500 characters long"
                          )
                        } else {
                          Promise.resolve()
                        }
                      },
                      message:
                        "Your message must be between 1 and 500 characters long",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Write us a message...."
                    autoSize={{ minRows: 10, maxRows: 20 }}
                    style={{ fontSize: "16px" }}
                  />
                </FormItem>
              </Form>
              <button
                className="form-control submit-button"
                name="submit"
                onClick={sendEmail}
              >
                Send Message
              </button>
            </div>
          </div>
          <div style={{ backgroundColor: "white", height: "550px" }} />
        </div>
      </div>
    </section>
  )
}

export default ContactUs

